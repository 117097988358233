import { Injectable } from '@angular/core'

import { FindPageDto, PageDto, PageResultDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: FindPageDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/pages', findPageDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PageDto>(`/admin/pages/${id}`)
  }

  async updateImage(id: string, imageId: string) {
    return await this.httpService.put(`/admin/pages/${id}/image`, { "fileId": imageId })
  }

  async update(id: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/pages/${id}`, updatePageDto)
  }
}
