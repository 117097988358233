import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { DashboardDto, FilterDateRangeDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<DashboardDto>(`/admin/dashboard`)
  }

  async export(dateRangeDto: FilterDateRangeDto) {
    return await this.httpService.get<any>(`/admin/dashboard/export`, dateRangeDto)
  }

  // async cert() {
  //   return await this.httpService.post<any>(`/certificates`)
  // }
}
