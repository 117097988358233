import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, UpdateVideoBannerDto, VideoBannerDto } from '../dto'

@Injectable()
export class VideoBannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<PageResultDto<VideoBannerDto>>('/admin/video-banner')
  }

  async update(id: string, updateVideoBannerDto: UpdateVideoBannerDto) {
    return await this.httpService.put<Boolean>(`/admin/video-banner/${id}`, updateVideoBannerDto)
  }
}
