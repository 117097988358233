import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateOrUpdateQuizAnswerDto, FindQuizAnswerDto, PageResultDto, QuizAnswerDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class QuizAnswerService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findQuizAnswerDto: FindQuizAnswerDto) {
    return await this.httpService.get<PageResultDto<QuizAnswerDto>>(`/admin/quiz-answers`, findQuizAnswerDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<QuizAnswerDto>(`/admin/quiz-answers/${id}`)
  }

  async create(createQuizAnswerDto: CreateOrUpdateQuizAnswerDto) {
    return await this.httpService.post<QuizAnswerDto>(`/admin/quiz-answers`, createQuizAnswerDto)
  }

  async update(id: string, updateQuizQuestionDto: CreateOrUpdateQuizAnswerDto) {
    return await this.httpService.put<Boolean>(`/admin/quiz-answers/${id}`, updateQuizQuestionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/quiz-answers/${id}`)
  }
}
