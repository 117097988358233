import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateOrUpdateQuizDto, FindQuizDto, PageResultDto, QuizDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class QuizService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findQuizDto: FindQuizDto) {
    return await this.httpService.get<PageResultDto<QuizDto>>(`/admin/quizzes`, findQuizDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<QuizDto>(`/admin/quizzes/${id}`)
  }

  async create(createLearningModuleDto: CreateOrUpdateQuizDto) {
    return await this.httpService.post<QuizDto>(`/admin/quizzes`, createLearningModuleDto)
  }

  async update(id: string, updateLearningModuleDto: CreateOrUpdateQuizDto) {
    return await this.httpService.put<Boolean>(`/admin/quizzes/${id}`, updateLearningModuleDto)
  }

  async setLearningModule(id: string, learningModuleId: string) {
    return await this.httpService.put<Boolean>(`/admin/quizzes/${id}/learning-module`, { learningModuleId })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/quizzes/${id}`)
  }

  async publish(id: string, published: boolean) {
    if (published) {
      return await this.httpService.patch<Boolean>(`/admin/quizzes/${id}/publish`)
    }

    return await this.httpService.patch<Boolean>(`/admin/quizzes/${id}/unpublish`)
  }
}
