import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminQuizTakeDto, FindQuizTakeDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class QuizTakeService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findQuizTakeDto: FindQuizTakeDto) {
    return await this.httpService.get<PageResultDto<AdminQuizTakeDto>>(`/admin/quiz-take`, findQuizTakeDto)
  }

  async export(findQuizTakeDto: FindQuizTakeDto) {
    return await this.httpService.get<any>(`/admin/quiz-take/export`, findQuizTakeDto)
  }
}
