import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, QuoteMessageDto, UpdateQuoteMessageDto } from '../dto'

@Injectable()
export class CeoMessageService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<PageResultDto<QuoteMessageDto>>('/admin/quote-message')
  }

  async update(id: string, updateQuoteMessageDto: UpdateQuoteMessageDto) {
    return await this.httpService.put<Boolean>(`/admin/quote-message/${id}`, updateQuoteMessageDto)
  }
}
