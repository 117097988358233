import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { BannerDto, PageResultDto, UpdateBannerDto } from '../dto'

@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<PageResultDto<BannerDto>>('/admin/banner')
  }

  async update(id: string, updateVideoBannerDto: UpdateBannerDto) {
    return await this.httpService.put<Boolean>(`/admin/banner/${id}`, updateVideoBannerDto)
  }
}
