import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateOrUpdateQuizQuestionDto, FindQuizQuestionDto, PageResultDto, QuizQuestionDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class QuizQuestionService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findQuizQuestionDto: FindQuizQuestionDto) {
    return await this.httpService.get<PageResultDto<QuizQuestionDto>>(`/admin/quiz-questions`, findQuizQuestionDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<QuizQuestionDto>(`/admin/quiz-questions/${id}`)
  }

  async create(createOrUpdateQuizQuestionDto: CreateOrUpdateQuizQuestionDto) {
    return await this.httpService.post<QuizQuestionDto>(`/admin/quiz-questions`, createOrUpdateQuizQuestionDto)
  }

  async update(id: string, createOrUpdateQuizQuestionDto: CreateOrUpdateQuizQuestionDto) {
    return await this.httpService.put<Boolean>(`/admin/quiz-questions/${id}`, createOrUpdateQuizQuestionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/quiz-questions/${id}`)
  }
}
