import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, UserDetailDto, QuizTakeDto, CreateOrUpdateUserDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/admin/users', findUserDto)
  }

  async findFinalHistory(id: string) {
    return await this.httpService.get<QuizTakeDto[]>(`/admin/users/${id}/final-score-histories`)
  }

  async findOne(id: string) {
    return await this.httpService.get<UserDetailDto>(`/admin/users/${id}`)
  }

  async create(createUserDto: CreateOrUpdateUserDto) {
    return await this.httpService.post<Boolean>(`/admin/users`, createUserDto)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/users/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/users/${id}/disable`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/users/${id}`)
  }

  async award(id: string, checked: boolean) {
    return await this.httpService.post<Boolean>(`/admin/users/${id}/award`, { checked })
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<Boolean>(`/admin/users/import`, formData, config)
  }
}
