import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { PageResultDto, ResetSettingDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'

@Injectable()
export class ResetSettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ResetSettingDto>>(`/admin/reset-setting`, pagingDto)
  }

  async create() {
    return await this.httpService.post<Boolean>(`/admin/reset-setting`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/reset-setting/${id}`)
  }
}
