import { Injectable } from '@angular/core'

import * as _ from 'lodash'
import { CreateOrUpdateLearningModuleDto, FindLearningModuleDto, LearningModuleDto, PageResultDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class LearningModuleService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findLearningModuleDto: FindLearningModuleDto) {
    return await this.httpService.get<PageResultDto<LearningModuleDto>>(`/admin/learning-modules`, findLearningModuleDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<LearningModuleDto>(`/admin/learning-modules/${id}`)
  }

  async create(createLearningModuleDto: CreateOrUpdateLearningModuleDto) {
    return await this.httpService.post<LearningModuleDto>(`/admin/learning-modules`, createLearningModuleDto)
  }

  async update(id: string, updateLearningModuleDto: CreateOrUpdateLearningModuleDto) {
    return await this.httpService.put<Boolean>(`/admin/learning-modules/${id}`, updateLearningModuleDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/learning-modules/${id}`)
  }

  async order(learningModuleIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/learning-modules/order-linked-list`, { learningModuleIds })
  }

  async setActive(id: string, active: boolean) {
    if (active) {
      return await this.httpService.patch<Boolean>(`/admin/learning-modules/${id}/active`)
    }

    return await this.httpService.patch<Boolean>(`/admin/learning-modules/${id}/inactive`)
  }
}
