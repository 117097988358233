import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateOrUpdateLearningLessonDto, FindLearningLessonDto, LearningLessonDto, LearningModuleDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class LearningLessonService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findLearningModuleDto: FindLearningLessonDto) {
    return await this.httpService.get<PageResultDto<LearningLessonDto>>(`/admin/learning-lessons`, findLearningModuleDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<LearningLessonDto>(`/admin/learning-lessons/${id}`)
  }

  async create(createLearningModuleDto: CreateOrUpdateLearningLessonDto) {
    return await this.httpService.post<LearningModuleDto>(`/admin/learning-lessons`, createLearningModuleDto)
  }

  async update(id: string, updateLearningModuleDto: CreateOrUpdateLearningLessonDto) {
    return await this.httpService.put<Boolean>(`/admin/learning-lessons/${id}`, updateLearningModuleDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/learning-lessons/${id}`)
  }

  async order(learningLessonIds: string[], learningModuleId: string) {
    return await this.httpService.put<Boolean>(`/admin/learning-lessons/order-linked-list`, { learningLessonIds, learningModuleId })
  }
}
